import React from "react";
import moment from "moment/moment";
import NumberFormat from "react-number-format";
import { last, range } from 'lodash';
import { monthList } from "@/pages/CreateProject/Budget/util";

const withValueLimit = ({ value }) => value === '-' || Math.abs(value) < 100_000_000;

export const InputNumTab = (props) =>
    <NumberFormat {...props}
                  onValueChange={({ value }) => props.onChange(value)}
                  onChange={undefined}
                  value={props.value}
                  thousandSeparator={" "}
                  isNumericString={true}
                  decimalScale={0}
                  isAllowed={withValueLimit} />

export const getNextEffectiveDateFromNow = (list) => {
    const lastList = last<any>(list);
    if (lastList) {
        const lastDate = moment(lastList.effectiveDate);
        if (lastDate.diff(moment(), 'months') >= 0) {
            return lastDate
              .add(1, 'months')
              .format('YYYY-MM-DD');
        }
    }

    return moment().format('YYYY-MM-DD');
}

export const availYearFromNow = (selectedYear) => {
    const now = moment();
    const yearList = range(0, 2)
      .map(index => now.year() + index)
      .map(year => ({
          label: year,
          value: year,
          isDisabled: false,
      }));

    if (selectedYear && selectedYear < now.year()) {
        yearList.unshift({
            label: selectedYear,
            value: selectedYear,
            isDisabled: true,
        })
    }

    return yearList;
}

export const availMonthFromNow = (selectedYear) => {
    const now = moment();

    return monthList.map(m => ({
        ...m,
        isDisabled: selectedYear < now.year() || (selectedYear === now.year() && Number(m.value) < now.month() + 1),
    }));
}

export interface BudgetContractedTableProps {
    data: BudgetPredictionData
    yearFilter: number
    filterChange: any
    getSumCol: any
    tableHash: any
}

export interface BudgetPredictionInputTableProps {
    data: BudgetPredictionData
    isEdit: boolean
    yearFilter: number
    onUpdate: (_: BudgetPredictionData) => void
    filterChange: any
    getSumCol: any
    tableHash: any
}

export interface CapitalizationPayrollTableProps {
    data: CapitalizationPayrollItem[]
    isEdit: boolean
    yearFilter: number
    onUpdate: (_: CapitalizationPayrollItem[] | ((_: CapitalizationPayrollItem[]) => void)) => void
    filterChange: any
    getSumCol: any
    tableHash: any
}

export const getDefaultBudgetPredictionInput = (nextEffectiveDate: string = moment().format('YYYY-MM-DD')): BudgetPredictionInputItem => ({
    effectiveDate: nextEffectiveDate,
    prediction: null,
});

export const getDefaultCapitalizationPayroll = (nextEffectiveDate: string = moment().format('YYYY-MM-DD')): CapitalizationPayrollItem => ({
    effectiveDate: nextEffectiveDate,
    okb: null,
    dds: null,
});

export const getDefaultPpmBudgetSource = (): PpmRequestBudgetSource => ({
  r12Code: '',
  typeId: null,
  name: '',
  isEdit: false,
  total: null,
});