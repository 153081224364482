import i18n from "i18next";
import { isFunction } from 'lodash';
import React, { createRef } from "react";

import AcceptDelete from "@/components/Helper/AcceptDelete";
import ButtonSimple from "@/elements/ButtonSimple";
import GetOrderFromR12 from "#/pages/CreateProject/Blocks/BudgetPrediction/GetOrderFromR12";
import CapitalizationPayrollTable from "#/pages/CreateProject/Blocks/BudgetPrediction/CapitalizationPayrollTable";
import BudgetPredictionOrderTable from "#/pages/CreateProject/Blocks/BudgetPrediction/PredictionOrderTable";
import { isSkipFiltered } from "@/pages/CreateProject/Budget/util";
import ModalAsync from "@/components/ModalAsync";
import { useDeviceContext } from "@/context/DeviceContext";
import { getDictByCode, getDictCodeById } from '@/utils';
import { ProjectStage, ProjectStatus, UserRight } from '@/config/const';
import { useCheckRight } from '@/utils/hooks';
import { useAppSelector } from '@/store';

export const checkEditBudgetPrediction = (projectData, checkRight, dict) => {
  const isEditRight = checkRight(UserRight.EDIT_BUDGET_PREDICTION);
  const isPredictionCoordination = getDictByCode(dict.budgetPredictionStatus, 'COORDINATION')?.id === projectData.budgetPredictionStatusId
  const projectStage = ProjectStage[getDictCodeById(dict.stage, projectData.stageId)];
  const isProjectStageClose = [ProjectStage.CLOSE, ProjectStage.ASSESSMENT_BENEFITS].includes(projectStage);
  const projectStatus = ProjectStatus[getDictCodeById(dict.status, projectData.statusId)];
  const isProjectArchive = ProjectStatus.ARCHIVE === projectStatus;
  const isProjectStatusRelease = [ProjectStatus.RELEASE, ProjectStatus.COORDINATION].includes(projectStatus);
  const isProjectClosed = projectData.isClosed;

  return isEditRight && !isPredictionCoordination && !isProjectClosed && !isProjectArchive
    && (!isProjectStatusRelease || !isProjectStageClose);
}

const EditBlockBudgetPrediction = ({
  projectVersionId,
  getOrdersFromR12,
  onSelectR12Orders,
  onCancelSelectR12Orders,
  isShowRemoveOrder,
  removeOrders,
  addNewOrder,
  updateExistingOrdersFromR12,
  state,
  setState,
}) => {
  const projectBase = i18n.t('base');
  const r12UpdateModal = createRef<ModalAsync>();
  const { isMobile } = useDeviceContext();
  const checkRight = useCheckRight();
  const [projectData, dict] = useAppSelector(st => [
    st.NewProject.newProjectData,
    st.dict,
  ]);
  const isEditBudgetPrediction = checkEditBudgetPrediction(projectData, checkRight, dict);

  const capitalizationFilterChange = (val: number) => {
    setState(() => ({
      capitalizationYearFilter: val
    }));
  };

  const updateCapitalization = (newList) => {
    setState(prevState => ({
      data: {
        ...prevState.data,
        capitalizationList: isFunction(newList) ? newList(prevState.data.capitalizationList) : newList,
      }
    }));

  };

  const onToggleOrder = (orderId) => {
    setState(({ predictionList }) => ({
      predictionList: predictionList.map(item => ({
        ...item,
        isChecked: item.id === orderId ? !item.isChecked : item.isChecked
      }))
    }));
  };


  const onToggleOrderAll = () => {
    setState(({ predictionList }) => {
      const isChecked = !predictionList.every(({ isChecked }) => isChecked);

      return {
        predictionList: predictionList.map(item => ({
          ...item,
          isChecked
        }))
      };
    });
  };

  const getCapitalizationSumCol = (colName: string, isFilter: boolean = true) => {
    return state.data.capitalizationList.reduce((sum, item) => {
      if (isFilter && isSkipFiltered(item, state.capitalizationYearFilter)) {
        return sum;
      }

      return sum + +(item[colName] || 0);
    }, 0);
  };

  const openUpdateR12Modal = () => {
    return r12UpdateModal.current.open().then(updateExistingOrdersFromR12, () => null);
  }

  return (
    <>
      <div className="row">
        {isMobile
          ? <div className="col-md-12 marginBottom-20 wrapper-option prediction-table-title">
            <h3 className="h3-felix marginBottom-5 nowrap">{'Прогноз бюджета'}</h3>
            {isEditBudgetPrediction && (
              <div>
                <div>
                  <GetOrderFromR12 projectVersionId={projectVersionId}
                                   getWorkId={getOrdersFromR12}
                                   onSelect={onSelectR12Orders}
                                   onReject={onCancelSelectR12Orders}/>
                </div>
                <div>
                  <ButtonSimple children="Добавить" onClick={addNewOrder}/>
                </div>
                <div>
                  <ButtonSimple children="Обновить привязанные заказы из R12" onClick={openUpdateR12Modal}/>
                </div>
                {isShowRemoveOrder && (
                  <div>
                    <AcceptDelete
                      className="button_Link_borderNone button_Link_borderNone_remove"
                      onOk={removeOrders}
                      buttonType="text"
                    >Удалить</AcceptDelete>
                  </div>
                )}
              </div>
            )}
          </div>
          : <div className="col-md-12 flex-space-between marginBottom-20 wrapper-option prediction-table-title">
          <h3 className="h3-felix marginBottom-0 nowrap">{'Прогноз бюджета'}</h3>
          {isEditBudgetPrediction && (
            <div className="text-align-right nowrap marginLeft-20">
              <GetOrderFromR12 projectVersionId={projectVersionId}
                               getWorkId={getOrdersFromR12}
                               onSelect={onSelectR12Orders}
                               onReject={onCancelSelectR12Orders}/>
              <ButtonSimple children="Добавить" onClick={addNewOrder}/>
              <ButtonSimple children="Обновить привязанные заказы из R12" onClick={openUpdateR12Modal}/>
              {isShowRemoveOrder && (
                <AcceptDelete
                  className="button_Link_borderNone button_Link_borderNone_remove"
                  onOk={removeOrders}
                  buttonType="text"
                >Удалить</AcceptDelete>
              )}
            </div>
          )}
        </div>}
        <div className="col-12 mb-3">
          <BudgetPredictionOrderTable
            data={state.predictionList}
            projectBase={projectBase}
            projectId={projectVersionId}
            onToggleOrder={onToggleOrder}
            onToggleAll={onToggleOrderAll}
            isShowRemove={isEditBudgetPrediction}
            works={[state.data]}
          />
        </div>
        <h3 className="h3-felix">Капитализация ФОТ и ГПХ</h3>
        <CapitalizationPayrollTable
          data={state.data.capitalizationList}
          isEdit={isEditBudgetPrediction}
          yearFilter={state.capitalizationYearFilter}
          onUpdate={updateCapitalization}
          filterChange={capitalizationFilterChange}
          getSumCol={getCapitalizationSumCol}
          tableHash={state.capitalizationTableHash}
        />
        <ModalAsync title="Внимание" ref={r12UpdateModal} isAlert={false} okTitle='Да' cancelTitle='Нет'>
          Будут обновлены все заказы из r12 с выбранными системными номерами.
          Вы уверены, что хотите обновить все заказы из r12 с выбранными системными номерами?
        </ModalAsync>
      </div>
    </>
  );
};

export default EditBlockBudgetPrediction;