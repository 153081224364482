import i18n from 'i18next';
import { get, isEmpty, keyBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { getBasicNewProjectData } from '@/actions/getBasicNewProjectData';
import { modalShow } from '@/actions/modal';
import ModalAsync from '@/components/ModalAsync';
import ProjectHeader from '@/components/ProjectHeader';
import HeaderButtons from '@/pages/CreateProject/Basic/HeaderButtons';
import { getDefaultCapitalizationPayroll } from '#/pages/CreateProject/Blocks/BudgetPrediction/helper';
import EditBlockBudgetPrediction from '@/pages/CreateProject/Blocks/Components/EditBlockBudgetPrediction';
import EditBlockView from '@/pages/CreateProject/Blocks/Components/EditBlockView';
import { isEmptyNotResponsible, NO_TEAM_KEY } from '@/pages/CreateProject/Blocks/Components/ResponsibleForm';
import { checkMilestone, checkTask, workGroupSort } from '@/pages/CreateProject/Blocks/utils';
import services from '@/services';
import service from '@/services';
import {
  checkEdit,
  checkRight,
  getDictByCode,
  getDictByName,
  getDictObj,
  isEmptyValues,
  momentToSelectDate, parseDate
} from '@/utils';
import { withRouterParams } from '@/utils/router';
import { sortDatedBudget } from '@/pages/CreateProject/Budget/util';
import { workDataLoader } from '@/pages/CreateProject/Blocks/WorkList';
import {
  getAutoFakeIndex,
  getDateEndForDuration,
  updateWorkDataWithChecks
} from '@/components/Gantt/util/utils';
import { createWeekendUtil } from '@/components/Gantt/util/dateUtil';

export const MAX_WORK_DURATION = 32;
export const WORK_NEED_DOC_MESSAGE = 'Внимание. Требуется приложить подтверждающие документы или указать ссылку на информацию о выполнении блока работ в другой системе для изменения данных по задаче';

const skupDefault = {
  dateStart: {
    from: null,
    to: null
  },
  dateEndPlan: {
    from: null,
    to: null
  },
  dateEndFact: {
    from: null,
    to: null
  },
};

const progressDefault = {
  nameNaturalIndex: null,
  plannedValue: null,
  factValue: null,
};

const post = (projectId, data) => services.post(`/work/project/${projectId}`, data);
const put = (workId, data) => services.put(`/work/${workId}`, data);
const postShadow = (projectId, data) => services.post(`/work/project/shadow/${projectId}`, data);
const putShadow = (workId, data) => services.put(`/work/shadow/${workId}`, data);
const defaultCapitalizationList = () => [getDefaultCapitalizationPayroll()];
const saveOrders = (workId, orders) => services.post(`/budget/prediction/order/work/${workId}/update/r12`, orders);

const getGroupedTeams = (data) => {
  const teams = {};
  teams[NO_TEAM_KEY] = [];
  data && data.forEach(item => {
    if (!item?.workTeamId) {
      teams[NO_TEAM_KEY].push(item);
    } else if (Object.keys(teams).includes(`${item.workTeamId}`)) {
      teams[`${item.workTeamId}`].push(item);
    } else {
      teams[`${item.workTeamId}`] = [];
      teams[`${item.workTeamId}`].push(item);
    }
  });
  return teams;
}


export const prepareWork = (data, isInitResponsibleList = true) => {
  let newData = {...data}

  if (isInitResponsibleList) {
    const groupedTeams = getGroupedTeams(data?.responsibleList);
    newData = {
      ...newData,
      isResponsibleListInit: true,
      responsibleList: groupedTeams || [],
      responsibleListMain: groupedTeams[NO_TEAM_KEY] || [],
    }
  }

  return {
    ...newData,
    skup: data.skup || skupDefault,
    capitalizationList: isEmpty(data.capitalizationList) ? defaultCapitalizationList() : data.capitalizationList.sort(sortDatedBudget),
  };
};

export const prepareWorkSave = (data, isBti: boolean = false) => {
  const flattenResponsibleList = [];
  Object.keys(data.responsibleList ?? {}).filter(key => key !== NO_TEAM_KEY).forEach(key => {
    flattenResponsibleList.push(data?.responsibleList[key]);
  });

  return {
    ...data,
    responsible: data.responsible ? data.responsible.value : null,
    userUpdate: null, //Вообще не отправляем, все равно обновляется на беке
    skup: data.skup?.projectId ? data.skup : null,
    capitalizationList: isBti ? data.capitalizationList : null,
    responsibleList: checkTask(data) && data.isResponsibleListInit
      ? [...flattenResponsibleList?.flat().filter(isEmptyNotResponsible),
        ...data.responsibleListMain?.filter(isEmptyNotResponsible)]
      : null,
  };
};

export const appendResponsibleFact = async (data, isFromGantt = false) => {
  if (!data || isEmptyValues(data.workId) || data.isResponsibleFactInit) {
    return data;
  }

  if (isFromGantt && !data.isResponsibleListInit) {
    data.responsibleList = await service.get(`/work/getResponsibleList/${data.workId}`);
    data = prepareWork(data);
    data.isResponsibleListInit = true;
  }

  data.isResponsibleFactInit = true;

  if (isEmptyValues(data.responsibleList)) {
    return data;
  }

  const responsibleFact = keyBy(await service.get('/timesheet/getFact', { staticWorkId: data.workId }), 'workResponsibleId');

  if (isFromGantt) {
    Object.keys(data.responsibleList)
      .forEach(team => data.responsibleList[team]
        .forEach(item => item.workFact = responsibleFact[item.id]?.val));
  } else {
    data.responsibleList.forEach(item => item.workFact = responsibleFact[item.id]?.val);
  }
  return data;
};

class EditBlock extends React.Component<any, {
  data: Work;
  getAllWork: Work[];
  getAllLink: GanttTableLink[];
  shadowSaveResult: {
    isEdit: boolean;
    item: any;
  };
  [key: string]: any;
}> {
  docs: any;
  modalRefNeedCreate: any;
  editBlockViewFuncRef: any;
  constructor(props) {
    super(props);

    this.state = {
      data: ({
        typeId: 1,
        projectId: props.newProjectData.projectId,
        skup: skupDefault,
        ...progressDefault,
        capitalizationList: defaultCapitalizationList(),
        responsibleListMain: []
      } as Work),
      getAllWork: [],
      getAllLink: [],
      initStatusId: null,
      capitalizationYearFilter: -1,
      capitalizationTableHash: Math.random(),
      predictionList: [],
      shadowSaveResult: {
        isEdit: false,
        item: undefined
      }
    };

    this.docs = React.createRef();
    this.modalRefNeedCreate = React.createRef();
    this.editBlockViewFuncRef = React.createRef();
  }

  componentDidMount() {
    const { projectVersionId } = this.props;

    if (this.props.isEdit) {
      this.getData();
    } else {
      this.setState({
        data: {
          ...this.state.data,
          id: getAutoFakeIndex(),
          isResponsibleListInit: true,
        }
      });
    }

    workDataLoader(projectVersionId, { params: JSON.stringify({ withLinked: true }) })
      .then((body) => {
        this.setState({ getAllWork: workGroupSort(body) });
      });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isEdit && this.props.isEdit) {
      this.getData();
    }
  }

  getDataFromServer = () =>
    this.props.getNewProjectData(this.props.projectVersionId);

  getWork = (workId) => services.get(`/work/${workId}`);

  getPrediction = (workId) => services.get(`/budget/prediction/order/work/${workId}`);
  removePrediction = (orderIds) => services.post(`/budget/prediction/order/remove/list`, orderIds);

  getLinks = (projectVersionId) => services.get(`/work/link/project/${projectVersionId}`);

  updateOrdersDataFromR12ById = (workId, orderIds) =>
    services.put(`/budget/prediction/order/project/${this.props.projectVersionId}/work/${workId}/updateExisting/r12`,
    orderIds);

  getData = async () => {
    const { item } = this.props;

    let data = await this.getWork(item);
    data = await appendResponsibleFact(data);
    data = prepareWork(data);
    this.setState({
      data: {
        ...data,
        dateStartPrev: parseDate(data.dateStart),
        dateEndPrev: parseDate(getDateEndForDuration(data, this.props.dict.workStatus)),
      },
      initStatusId: data.statusId
    });

    this.getDataFromServer();
    this.loadPrediction();
    this.loadLinks();
  };

  loadPrediction = () => {
    const { item } = this.props;

    this.getPrediction(item).then((data: BudgetPredictionOrder[]) => this.setState({
      predictionList: data.sort((o1, o2) => o1.num - o2.num),
    }));
  };

  loadLinks = () => {
    this.getLinks(this.props.projectVersionId).then(getAllLink => this.setState({ getAllLink }));
  }

  getWorkStatus = (work) => {
    return get(getDictObj(this.props.dict.workStatus, work.statusId), 'code');
  };

  save = async () => {
    await this.updateLoadBlock(() => {
      this.getDataFromServer();
      this.afterSave();
    });
  };

  shadowSave = () => {
    const isEdit = this.state.shadowSaveResult.isEdit || this.props.isEdit;
    const projectVersionId = this.props.projectVersionId;
    const item = this.state.shadowSaveResult?.item ?? this.props.item;

    const data = prepareWorkSave(this.state.data, this.isBti());
    data.links = this.state.getAllLink.filter(link => link.fromId === item || link.toId === item);

    if (isEdit) {
      return putShadow(item, data)
        .then(() => this.docs.current?.sendFiles(projectVersionId, data.workId))
        .then((result) => this.setState({shadowSaveResult: {isEdit: true, item: result?.id}}));
    }

    return postShadow(projectVersionId, data)
      .then((result) => this.setState({shadowSaveResult: {isEdit: !!result?.id, item: result?.id}}));
  }

  afterSave = async () => {
    this.props.history(this.props.url, {state: {disableBlock: true}});
  };

  updateLoadBlock = async (callback: ((updatedItem: Work) => void) = () => {}) => {
    const item = this.state.shadowSaveResult.item ?? +this.props.item;
    const localData = this.state.data;
    const localLinks = this.state.getAllLink;

    const saveLocal = (isUpdateChildren: boolean, dropLinkChildrenIds: number[] = []) => {
      const data = { ...prepareWorkSave(localData, this.isBti()), isUpdateChildren };
      data.links = localLinks.filter(link => (link.fromId === this.state.data.id || link.toId === this.state.data.id)
        && !dropLinkChildrenIds.includes(link.toId));

      const isEdit = this.state.shadowSaveResult.isEdit || this.props.isEdit;
      const projectVersionId = this.props.projectVersionId;

      if (isEdit) {
        return Promise.all([
          put(item, data),
          this.docs.current?.sendFiles(projectVersionId, data.workId),
        ]).then(res => callback?.(res[0]));
      }

      return post(projectVersionId, data).then(updatedItem => callback?.(updatedItem));
    }

    if (!this.isStatusDraft()
      && ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(this.getWorkStatus(localData))
      && localData.statusId !== this.state.initStatusId
      && this.docs.current?.isEmpty()
      && isEmpty(localData.urlList.filter(url => !url.isRemoved))
    ) {
      if (!checkMilestone(localData) || !this.props.newProjectData?.isSkipCreateSystemMilestoneAndCheck) {
        this.props.showModal({
          message: WORK_NEED_DOC_MESSAGE,
          title: 'Внимание'
        });
        throw WORK_NEED_DOC_MESSAGE;
      }
    }

    const localDataWithId = { ...localData };
    return updateWorkDataWithChecks([...this.state.getAllWork, localDataWithId], localLinks, this.props.dict.workStatus,
      this.props.weekendUtil, this.props.showModal, localDataWithId.id, () => localDataWithId, saveLocal);
  };

  cancel = () => {
    this.props.history(this.props.url, {state: {disableBlock: true}});
  };

  deleteTask = () => {
    const { history, item } = this.props;
    return services.remove(`/work/${item}`).then(() => {
      history(this.props.url);
    });
  };

  isStatusDraft = () => {
    return getDictByCode(this.props.dict.status, 'DRAFT')?.id === this.props.newProjectData.statusId;
  };

  isPredictionCoordinate = () => {
    return getDictByCode(this.props.dict.budgetPredictionStatus, 'COORDINATION')?.id === this.props.newProjectData.budgetPredictionStatusId;
  };

  isEditBudgetPrediction = () => {
    return this.props.checkRight("EDIT_BUDGET_PREDICTION") && !this.isPredictionCoordinate();
  };

  getSelectedOrderIds = () => {
    return this.state.predictionList
      .filter(item => item.isChecked)
      .map(item => item.id);
  };

  addNewOrder = async () => {
    if (!this.props.isEdit) {
      this.editBlockViewFuncRef.current.setIsDirty(false);
      this.modalRefNeedCreate.current.open("Для добавления заказа необходимо сохранить текущий блок работ")
        .then(this.updateLoadBlock((newData) => {
          if (!newData?.id) {
            this.props.history(this.props.url, {state: {disableBlock: true}});
            throw new Error();
          }
          return this.getDataFromServer().then(() => {
            this.linkToNewWork(newData.id);
            this.linkToNewOrder(newData.id);
          });
        }))
        .catch(e => { });
    }

    this.linkToNewOrder(this.props.item);
  };

  linkToNewOrder = (workId) => {
    const { newProjectData } = this.props;
    const projectBase = i18n.t('base');

    if (!projectBase || !newProjectData || !workId) {
      return;
    }

    this.props.history(`/${projectBase}/${newProjectData.id}/work/${workId}/prediction/add`);
  };

  linkToNewWork = (workId) => {
    const { newProjectData } = this.props;
    const projectBase = i18n.t('base');

    if (!projectBase || !newProjectData || !workId) {
      return;
    }

    this.props.history(`/${projectBase}/${newProjectData.id}/work/${workId}`);
  };

  getOrdersFromR12 = async () => {
    if (!this.props.isEdit) {
      this.editBlockViewFuncRef.current.setIsDirty(false);
      return this.modalRefNeedCreate.current.open("Для добавления заказа необходимо сохранить текущий блок работ")
        .then(this.updateLoadBlock((newData) => {
          if (!newData?.id) {
            this.props.history(this.props.url, {state: {disableBlock: true}});
            throw new Error();
          }
          return newData.id;
        }));
    }

    return Promise.resolve(this.props.item);
  };

  onSelectR12Orders = (workId, orders) => {
    saveOrders(workId, orders.map(o => {
      return {
        r12code: o.r12code,
        r12name: o.r12name,
        docNumber: o.sysNumber,
        subjectDescription: o.description,
        contractor: o.contractor,
        signingDate: o.signingDate ? momentToSelectDate(moment(o.signingDate).startOf('month')) : null,
        completionDate: o.completionDate ? momentToSelectDate(moment(o.completionDate).startOf('month')) : null,
        dueDate: o.dueDate ? momentToSelectDate(moment(o.dueDate).startOf('month')) : null,
        okb: o.okb || 0,
        dds: o.dds || 0,
        isFromR12: true,
        orderActionId: getDictByName(this.props.dict.budgetPredictionOrderAction, o?.actionName)?.id,
      };
    })).then(() => {
      if (this.props.item) {
        this.loadPrediction();
        return;
      }

      this.linkToNewWork(workId);
    });
  };

  onCancelSelectR12Orders = (workId) => {
    if (!this.props.item) {
      this.linkToNewWork(workId);
    }
  };

  removeOrders = () => {
    this.removePrediction(this.getSelectedOrderIds()).then(this.loadPrediction);
  };

  updateOrdersDataFromR12 = () => {
    const { item } = this.props;
    this.updateOrdersDataFromR12ById(item, this.getSelectedOrderIds()).then(this.loadPrediction);
  };

  isBti = () => this.props.newProjectData.isBti;

  setData = (func) => {
    this.setState((oldState) => ({
      data: func(oldState.data)
    }));
  };

  updateState = (arg) => {
    this.setState(arg);
  };

  render() {
    const { isEdit, projectVersionId } = this.props;

    const isEditBudgetPrediction = this.isEditBudgetPrediction();
    const isShowRemoveOrder = !isEmpty(this.getSelectedOrderIds());

    const isBti = this.isBti();

    return (
      <React.Fragment>
        <HeaderButtons backTo={this.props.url} />
        <div className="wrapper-option">
          <ProjectHeader title={`${isEdit ? 'Редактировать' : 'Добавить'} работу`} />
        </div>

        <EditBlockView
          data={this.state.data}
          setData={this.setData}
          links={this.state.getAllLink}
          setLinks={updateLinks => this.setState({ getAllLink: updateLinks(this.state.getAllLink) })}
          isEdit={isEdit}
          projectVersionId={projectVersionId}
          docs={this.docs}
          onCancel={this.cancel}
          onDeleteTask={this.deleteTask}
          onSave={this.save}
          shadowSave={this.shadowSave}
          isEditBudgetPredictionRight={isEditBudgetPrediction}
          getAllWork={this.state.getAllWork}
          funcRef={this.editBlockViewFuncRef}
          readonly={this.state.data.isBaseGroup}
        >
          {checkTask(this.state.data)
            && (!this.props.isEdit || this.state.data.workId)
            && isBti
            && (
              <EditBlockBudgetPrediction
                projectVersionId={projectVersionId}
                getOrdersFromR12={this.getOrdersFromR12}
                onSelectR12Orders={this.onSelectR12Orders}
                onCancelSelectR12Orders={this.onCancelSelectR12Orders}
                removeOrders={this.removeOrders}
                isShowRemoveOrder={isShowRemoveOrder}
                addNewOrder={this.addNewOrder}
                state={this.state}
                setState={this.updateState}
                updateExistingOrdersFromR12={this.updateOrdersDataFromR12}
              />
            )}
        </EditBlockView>

        <ModalAsync
          title="Внимание"
          ref={this.modalRefNeedCreate}
          okTitle="Сохранить"
          cancelTitle="Отменить"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  newProjectData: state.NewProject.newProjectData,
  dict: state.dict,
  checkRight: checkRight(state),
  isProjectEdit: checkEdit(state),
  weekendUtil: createWeekendUtil(state),
});

const mapDispatchToProps = (dispatch) => ({
  getNewProjectData: (id, cb) => dispatch(getBasicNewProjectData(id, cb)),
  showModal: (message, withThrow?, options?) => {
    dispatch(modalShow(message, options));
    if (withThrow) {
      throw message;
    }
  },
});

export default withRouterParams(connect(mapStateToProp, mapDispatchToProps)(EditBlock));
